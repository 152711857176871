import React,{ useEffect, useState } from 'react';
import './App.css'; 
import { BrowserRouter, Routes,Route } from "react-router-dom";
import { RouteList } from './Routes/Routes';
import Layout from './layouts/Layout';
import NotFound from './pages/NotFound';

function App() {
 
  
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Layout />}>
            { RouteList.map(function (item, index) {
              return <Route key={index} path={item.path} element={item.element}></Route>;
            })
            }
            <Route path="*" element={<NotFound />}></Route>
          </Route>
    </Routes>
        
      </BrowserRouter>
  );
}

export default App;
