import { Fragment } from "react";
import PageTitle from "../layouts/PageTitle";
import {Link} from 'react-router-dom'; 
import { ServiceContainer1, ServiceContainer2 } from "../components/ServiceContainer";
import WorkingProcess from "../components/WorkingProcess";
import PricingTable from "../components/PricingTable";



const CreativeAgencyPage = () => {
  return (
    <Fragment>
      <PageTitle pageName={"Creative Agency"} />

      <WorkingProcess 
          title="Every Step, Perfectly Executed" 
          subheadingTitle="Unveil Truewebmakers Tech's seamless 4-step approach to developing customized websites and apps. From concept to completion, we deliver tailored, user-friendly designs that elevate your business. Let’s collaborate to create something remarkable!"
          height="180px"
          width="180px" 
        
        />
        
      {/*====== Work Process Section End ======*/}
      {/*====== Feature Section Start ======*/}
      {/* <section className="feature-section section-gap-bottom">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="feature-img text-lg-left text-center content-mb-md-50">
                <img
                  src="../assets/img/illustrations/feature-illustration-02.png"
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="feature-text-block content-l-spacing">
                <div className="section-heading mb-30">
                  <h2 className="title">
                    10 Years Of Experience We Provide Solutions
                  </h2>
                  <span className="tagline">Best Web Design & Development Agency</span>
                </div>
                <div className="feature-lists">
                  <div className="simple-icon-box icon-left mb-30">
                    <div className="icon">
                      <i className="flaticon-crop" />
                    </div>
                    <div className="content">
                      <h4 className="title">Professional Agency</h4>
                      <p>
                        Consectetur adipisci velitsed quia non numquam eius mode
                        tempralabore et dolore magnam aliquam quaerat
                      </p>
                    </div>
                  </div>
                  <div className="simple-icon-box icon-left mb-30">
                    <div className="icon">
                      <i className="flaticon-crop" />
                    </div>
                    <div className="content">
                      <h4 className="title">Best Solutions Provider</h4>
                      <p>
                        Consectetur adipisci velitsed quia non numquam eius mode
                        tempralabore et dolore magnam aliquam quaerat
                      </p>
                    </div>
                  </div>
                </div>
                <a href="#" className="template-btn mt-10">
                  Start a Project <i className="far fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*====== Feature Section End ======*/}
      {/*====== Case Section Start ======*/}
      {/* <ServiceContainer1/> */}
      <ServiceContainer2 title={'Our Comprehensive Services'} subtitle="Delivering Industry-Leading Solutions with Excellence and Expertise" />
     
      
      {/*====== Case Section End ======*/}
      {/*====== Pricing Section Start ======*/}
      <PricingTable active={true}/>
    </Fragment>
  );
};
export default CreativeAgencyPage;
