// data.js
export const MyPortfolios = [
  {
    id: "bcca-project",
    title: "Building Cultural Connections Australia Inc.",
    short_description: "",
    feature_image: "/assets/img/portfolio/01.png",
    project_info: {
      client_name: "Asif M Hussain",
      project_category: "Website",
      location: "Physical address is 1/106 Derrimut Road, Hoppers Crossing VIC",
      project_start: "January 19, 2024",
      project_end: "January 30, 2024",
      website_live_link: "https://bcca.net.au/",
      tech_used: "Wordpress,Php,Jquery,MySQL",
    },
    description: (
      <>
        <div className="portfolio-details-content wow fadeInUp">
          <h2 className="portfolio-title">
            At BCCA, our journey is rooted in a deep-seated belief in the
            transformative power of cultural connections
          </h2>
          <p className="mb-30">
            At BCCA, our journey is rooted in a deep-seated belief in the
            transformative power of cultural connections. We started with a
            simple, yet profound vision: to create a society where every
            individual, irrespective of their cultural background, feels valued
            and included. Our story began in the diverse neighborhoods of West
            Melbourne, where we saw the potential for a more cohesive community
            fabric, woven together through understanding and respect.
          </p>
          <p className="mb-30">
            Sed ut perspiciatis unde omnis iste natus error voluptatem
            accusantium doloremque laudantium ,totam rem aperiam eaque ipsa quae
            aillo inventore veritatis et quasi architecto beatae vitae dicta
            sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit sed quia consequuntur magni dolores
            eos ratione voluptatem sequi nesciunt.Neque porro quisquam est, qui
            dolorem ipsum quia dolor sit amet, consectetur, adipisci velit
          </p>
          <div className="row">
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/01.png"
                alt="Image"
                className="mb-30"
              />
            </div>
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/01.png"
                alt="Image"
                className="mb-30"
              />
            </div>
          </div>
          <p>
            Join us as we continue to write this story of passion and
            perseverance, creating a world where every culture is not just
            acknowledged but celebrated. A world where every individual can
            proudly say, ‘I am part of the cultural mosaic that is BCCA.’ Dive
            into our journey, and let’s make history together in building a
            society rich in diversity and unity
          </p>
          <div className="row">
            <div className="col-lg-9">
              <div className="feature-lists mt-30">
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Professional Agency</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Best Solutions Provider</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Web &amp; Design Solutions</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quia non numquam eius modi tempora
            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "wbta-project",
    title: "Werribee Business and Tourism Association (WBTA)",
    short_description: "Founder & CEO",
    feature_image: "/assets/img/portfolio/02.png",
    project_info: {
      client_name: "Rakesh Singh",
      project_category: "Website",
      location: "Liaison officer: Rakesh Singh",
      project_start: "January 01, 2024",
      project_end: "Feb 08, 2024",
      website_live_link: "https://www.werribeebusinessandtourism.org.au/",
      tech_used: "Wordpress,Php,Jquery,MySQL",
    },
    description: (
      <>
        <div className="portfolio-details-content wow fadeInUp">
          <h2 className="portfolio-title">
            Werribee Business and Tourism Association (WBTA)
          </h2>
          <p className="mb-30">
            The WBTA was established in 2015 as the Wyndham Business and Tourism
            Association to provide events and services for businesses in the
            City of Wyndham, in Melbourne’s western suburbs – and those that
            would like to do business in the area. The organisation was formed
            out of the amalgamation of the former Wyndham Chamber of Commerce
            and Wyndham Tourism Association to create a stronger united entity
            that can deliver better outcomes for everyone involved.
          </p>
          <p className="mb-30">
            In August 2019, it became the Werribee Business and Tourism
            Association due to a renewed focus specifically on the Werribee CBD
            and Werribee South areas. Our membership represents business owners
            and their employees, students and education facilities, corporate,
            medium, small and micro businesses – the breadth of our membership
            provides a vibrant pool for business connections.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/02.png"
                alt="Image"
                className="mb-30"
              />
            </div>
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/02.png"
                alt="Image"
                className="mb-30"
              />
            </div>
          </div>
          <p>
            The Werribee Business and Tourism Association is funded only by
            memberships, sponsorships and events.
          </p>
          <div className="row">
            <div className="col-lg-9">
              <div className="feature-lists mt-30">
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Professional Agency</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Best Solutions Provider</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Web &amp; Design Solutions</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit, sed quia non numquam eius modi tempora
            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "hyperiontech-project",
    title: "Software Development & online marketing company",
    short_description: "Founder & CEO",
    feature_image: "/assets/img/portfolio/04.png",
    project_info: {
      client_name: "Arthua Ramasy",
      project_category: "Website",
      location: "Ground floor 470 St Kilda Road Melbourne VIC 3004",
      project_start: "January 01, 2024",
      project_end: "Feb 08, 2024",
      website_live_link: "https://hyperiontech.com.au/",
      tech_used: "Wordpress,Php,Jquery,MySQL",
    },
    description: (
      <>
        <div className="portfolio-details-content wow fadeInUp">
          <h2 className="portfolio-title">
          Hyperion Tech
          </h2>
          <p className="mb-30">
            At Hyperion Tech, our journey began with a vision to revolutionize
            the digital landscape. What started as a small team of passionate
            technologists has evolved into a leading force in software and
            application development. From our roots in Melbourne, we have
            expanded our reach, driving change and setting benchmarks in the
            tech industry. Our story is one of innovation, commitment, and
            relentless pursuit of excellence.{" "}
          </p>
          <p className="mb-30">
            Partnering with Hyperion Tech means engaging with a team dedicated
            to your success. Our approach is centered around building lasting
            relationships with our clients, providing support and strategic
            insights that extend far beyond project completion. We see ourselves
            as an extension of your team—a partner who is as invested in
            achieving your business goals as you are. Together, we navigate the
            complexities of the digital world, turning challenges into
            opportunities.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/04.png"
                alt="Image"
                className="mb-30"
              />
            </div>
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/04.png"
                alt="Image"
                className="mb-30"
              />
            </div>
          </div>
          <p>
          We pride ourselves on understanding your specific goals and delivering tailored solutions that drive success.With a proven track record and a focus on innovation, Hyperion Tech is dedicated to elevating your digital presence.
          </p>
          <div className="row">
            <div className="col-lg-9">
              <div className="feature-lists mt-30">
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Professional Agency</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Best Solutions Provider</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Web &amp; Design Solutions</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    id: "reves-project",
    title: "Reves La Veste",
    short_description: "Founder & CEO",
    feature_image: "/assets/img/portfolio/03.png",
    project_info: {
      client_name: "Rajni Rekha",
      project_category: "Website",
      location: "Ground floor 470 St Kilda Road Melbourne VIC 3004",
      project_start: "January 01, 2024",
      project_end: "Feb 08, 2024",
      website_live_link: "https://reves-laveste.com/",
      tech_used: "Wordpress,Wocommerce, Php,Jquery,MySQL",
    },
    description: (
      <>
        <div className="portfolio-details-content wow fadeInUp">
          <h2 className="portfolio-title">
          Reves La Veste
          </h2>
          <p className="mb-30">
          Reves La Veste embodies a modern stylistic narrative that articulates the ABC of professional life. With every stitch and silhouette, we celebrate individuality, empowering you to express your unique persona in the corporate realm. Our creations are more than just garments, they are an extension of your distinct professional narrative.
          </p>
          <p className="mb-30">
          Born from a desire to dress the uniqueness of personalities, Reves La Veste seeks to revolutionise workwear, blending aesthetics with functionality. Our designs are a tribute to women’s energetic and empowering strides in the corporate sector, aiding them in carving their unique identity.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/03.png"
                alt="Image"
                className="mb-30"
              />
            </div>
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/03.png"
                alt="Image"
                className="mb-30"
              />
            </div>
          </div>
          <p>
          Reves La Veste is committed to creating communities that inspire positive living. We collaborate with artisans and craft persons across India, bringing beautiful techniques of weaving, embroidery, printing, and dyeing into the limelight, empowering the communities behind these art forms.          </p>
          <div className="row">
            <div className="col-lg-9">
              <div className="feature-lists mt-30">
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Professional Agency</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Best Solutions Provider</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Web &amp; Design Solutions</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    id: "lifestageslegal-project",
    title: "Life Stages Legal",
    short_description: "Founder & CEO",
    feature_image: "/assets/img/portfolio/05.png",
    project_info: {
      client_name: "Jamie",
      project_category: "Website",
      location: "Suite 305, C5 Level 1, 2 Main Street, Point Cook. Point Cook Town Centre, Vic 3030",
      project_start: "January 01, 2024",
      project_end: "Feb 08, 2024",
      website_live_link: "https://lifestageslegal.com.au/",
      tech_used: "Wordpress,Wocommerce, Php,Jquery,MySQL",
    },
    description: (
      <>
        <div className="portfolio-details-content wow fadeInUp">
          <h2 className="portfolio-title">
          Reves La Veste
          </h2>
          <p className="mb-30">
          Discover personalised legal advice for every stage of life with Lifestages Legal. From buying your first home to starting a business, we’re here to guide you.          </p>
          <p className="mb-30">
          Welcome to Lifestages Legal, your trusted partner in navigating the legal aspects of life’s most significant milestones. At Lifestages Legal, we believe in offering tailored advice that grows with you. Whether you’re stepping into adulthood, purchasing your dream home, or laying the foundation for your first business, our team in Melbourne is committed to providing the support you need.
          </p>
          <div className="row">
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/05.png"
                alt="Image"
                className="mb-30"
              />
            </div>
            <div className="col-sm-6">
              <img
                src="../assets/img/portfolio/05.png"
                alt="Image"
                className="mb-30"
              />
            </div>
          </div>
          <p>
          Reves La Veste is committed to creating communities that inspire positive living. We collaborate with artisans and craft persons across India, bringing beautiful techniques of weaving, embroidery, printing, and dyeing into the limelight, empowering the communities behind these art forms.          </p>
          <div className="row">
            <div className="col-lg-9">
              <div className="feature-lists mt-30">
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Professional Agency</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Best Solutions Provider</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
                <div className="simple-icon-box icon-left mb-30 wow fadeInUp">
                  <div className="icon">
                    <i className="flaticon-crop" />
                  </div>
                  <div className="content">
                    <h4 className="title">Web &amp; Design Solutions</h4>
                    <p>
                      Consectetur adipisci velitsed quia non numquam eius mode
                      tempralabore et dolore magnam aliquam quaerat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  },
];
