 
export const MyTeamConstant =[
     
    {
        name : "Paras Sharma",
        designation : "Founder & CEO",
        image: "01.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Indu Sharma",
        designation : "Managing Director",
        image: "02.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Anuj Sharma",
        designation : "Lead Wordpress Developer",
        image: "03.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Priyanka Sharma",
        designation : "Business Development Manager",
        image: "04.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     }
];

export const ServicesConstant =[
     
    {
        "id": "web-development",
        "title": "Web Development",
        "iconClass": "",
        "iconImage": "/assets/img/services/coding.png",
        "link": "service-detail/web-development",
        "description": "The modern business landscape is highly competitive and progressive, which means that having an attractive website does not guarantee online success.",
        "service_detail_page": {
            "hero_section": {
                "title": "Expert Web Development Solutions",
                "subheading": "Building Websites That Drive Results",
                "feature1": "Custom Web Design",
                "feature_sub1": "Tailored designs that enhance user experience.",
                "feature1_image": "/assets/img/services/coding.png",
                "feature2": "Responsive Design",
                "feature_sub2": "Optimized for all devices and screen sizes.",
                "feature2_image": "/assets/img/services/coding.png",
                "feature3": "Performance Optimization",
                "feature_sub3": "Speed and efficiency improvements for better performance.",
                "feature3_image": "/assets/img/services/coding.png"
            },
            "step1_title": "Project Analysis",
            "step1_description": "We start by understanding your business needs, target audience, and goals to craft a tailored web development strategy.",
            "step1_image": "/assets/img/services/coding.png",
            "step2_title": "Design and Prototyping",
            "step2_description": "Our team creates wireframes and prototypes to visualize the design and functionality before development begins.",
            "step2_image": "/assets/img/services/coding.png",
            "step3_title": "Development and Testing",
            "step3_description": "We build your website with the latest technologies and conduct thorough testing to ensure everything works flawlessly.",
            "step3_image": "/assets/img/services/coding.png",
            "step4_title": "Launch and Support",
            "step4_description": "After launching your site, we provide ongoing support and maintenance to ensure its continued success and performance.",
            "step4_image": "/assets/img/services/coding.png"
        }
    },
    {
        "id": "custom-development",
        "title": "Custom Web-App Development",
        "iconClass": "",
        "iconImage": "/assets/img/services/development.png",
        "link": "service-detail/custom-development",
        "description": "Since the introduction of PHP in the 1990s, it has been the most popular and preferred server-side scripting language for building websites and web applications.",
        "service_detail_page": {
            "hero_section": {
                "title": "Tailored Web and App Solutions",
                "subheading": "Custom Development for Your Unique Needs",
                "feature1": "Bespoke Application Design",
                "feature_sub1": "Custom solutions tailored to your business requirements.",
                "feature1_image": "/assets/img/services/development.png",
                "feature2": "Scalable Architecture",
                "feature_sub2": "Build apps that grow with your business.",
                "feature2_image": "/assets/img/services/development.png",
                "feature3": "Integration Capabilities",
                "feature_sub3": "Seamless integration with existing systems and third-party services.",
                "feature3_image": "/assets/img/services/development.png"
            },
            "step1_title": "Requirement Gathering",
            "step1_description": "We work closely with you to gather and document detailed requirements for your custom application.",
            "step1_image": "/assets/img/services/development.png",
            "step2_title": "Design and Wireframing",
            "step2_description": "Our team creates detailed design mockups and wireframes to visualize the application's structure and functionality.",
            "step2_image": "/assets/img/services/development.png",
            "step3_title": "Development and Quality Assurance",
            "step3_description": "We develop your custom solution, followed by rigorous testing to ensure it meets all requirements and functions smoothly.",
            "step3_image": "/assets/img/services/development.png",
            "step4_title": "Deployment and Support",
            "step4_description": "After deployment, we offer ongoing support and updates to ensure your application remains current and effective.",
            "step4_image": "/assets/img/services/development.png"
        }
    },
    {
        "id": "web-design",
        "title": "Web Design",
        "iconClass": "",
        "iconImage": "/assets/img/services/illustration.png",
        "link": "service-detail/web-design",
        "description": "If you are wondering why your online business or corporate website is not getting the results you expected, there may be something wrong with the current web design.",
        "service_detail_page": {
            "hero_section": {
                "title": "Innovative Web Design Services",
                "subheading": "Designing Beautiful and Functional Websites",
                "feature1": "Creative Web Design",
                "feature_sub1": "Unique and engaging designs that capture your brand’s essence.",
                "feature1_image": "/assets/img/services/illustration.png",
                "feature2": "User-Centric Approach",
                "feature_sub2": "Designs focused on delivering exceptional user experiences.",
                "feature2_image": "/assets/img/services/illustration.png",
                "feature3": "Brand Consistency",
                "feature_sub3": "Ensuring your website aligns with your overall brand identity.",
                "feature3_image": "/assets/img/services/illustration.png"
            },
            "step1_title": "Design Briefing",
            "step1_description": "We begin with a comprehensive briefing to understand your design needs, goals, and brand identity.",
            "step1_image": "/assets/img/services/illustration.png",
            "step2_title": "Concept Development",
            "step2_description": "Our team develops design concepts and visual styles that align with your brand and business objectives.",
            "step2_image": "/assets/img/services/illustration.png",
            "step3_title": "Design Refinement",
            "step3_description": "We refine the chosen design concepts based on feedback to ensure they meet your expectations and requirements.",
            "step3_image": "/assets/img/services/illustration.png",
            "step4_title": "Final Delivery and Support",
            "step4_description": "Once the design is finalized, we deliver the assets and provide support for implementation and adjustments as needed.",
            "step4_image": "/assets/img/services/illustration.png"
        }
    },
    {
        "id": "shopify",
        "title": "Ecommerce Stores",
        "iconClass": "",
        "iconImage": "/assets/img/services/shopify.png",
        "link": "service-detail/shopify",
        "description": "Online stores have successfully taken over traditional retail. This is mainly due to the ease of purchase and the secure transaction process.",
        "service_detail_page": {
            "hero_section": {
                "title": "Powerful E-commerce Solutions",
                "subheading": "Building Effective Online Stores",
                "feature1": "Custom E-commerce Design",
                "feature_sub1": "Tailor-made designs to enhance online shopping experiences.",
                "feature1_image": "/assets/img/services/shopify.png",
                "feature2": "Secure Payment Integration",
                "feature_sub2": "Ensuring secure and smooth payment processes.",
                "feature2_image": "/assets/img/services/shopify.png",
                "feature3": "Inventory Management",
                "feature_sub3": "Efficient systems for managing products and orders.",
                "feature3_image": "/assets/img/services/shopify.png"
            },
            "step1_title": "E-commerce Strategy",
            "step1_description": "We develop a comprehensive e-commerce strategy tailored to your business needs and goals.",
            "step1_image": "/assets/img/services/shopify.png",
            "step2_title": "Design and Setup",
            "step2_description": "Our team designs and sets up your online store, ensuring a user-friendly interface and seamless shopping experience.",
            "step2_image": "/assets/img/services/shopify.png",
            "step3_title": "Testing and Launch",
            "step3_description": "We thoroughly test your e-commerce site to ensure all features work correctly before the official launch.",
            "step3_image": "/assets/img/services/shopify.png",
            "step4_title": "Post-Launch Support",
            "step4_description": "After launch, we provide ongoing support and maintenance to ensure your store operates smoothly and effectively.",
            "step4_image": "/assets/img/services/shopify.png"
        }
    },
    {
        "id": "wordpress",
        "title": "WordPress Websites",
        "iconClass": "",
        "iconImage": "/assets/img/services/wordpress.png",
        "link": "service-detail/wordpress",
        "description": "WordPress is a powerful and versatile content management system (CMS) that enables users to create and manage websites with ease. Known for its user-friendly interface and extensive customization options, WordPress allows individuals and businesses to build everything from simple blogs to complex e-commerce sites.",
        "service_detail_page": {
            "hero_section": {
                "title": "Comprehensive WordPress Solutions",
                "subheading": "Build and Manage Your Website with Ease",
                "feature1": "Custom WordPress Design",
                "feature_sub1": "Unique designs tailored to your needs and preferences.",
                "feature1_image": "/assets/img/services/wordpress.png",
                "feature2": "Plugin Integration",
                "feature_sub2": "Adding essential functionalities to enhance your website’s capabilities.",
                "feature2_image": "/assets/img/services/wordpress.png",
                "feature3": "Ongoing Maintenance",
                "feature_sub3": "Regular updates and support to keep your site running smoothly.",
                "feature3_image": "/assets/img/services/wordpress.png"
            },
            "step1_title": "Requirement Analysis",
            "step1_description": "We assess your needs and preferences to determine the best approach for your WordPress website.",
            "step1_image": "/assets/img/services/wordpress.png",
            "step2_title": "Design and Development",
            "step2_description": "We create a custom WordPress theme and develop your site based on your specifications.",
            "step2_image": "/assets/img/services/wordpress.png",
            "step3_title": "Content Integration and Testing",
            "step3_description": "We integrate your content and conduct thorough testing to ensure everything functions correctly.",
            "step3_image": "/assets/img/services/wordpress.png",
            "step4_title": "Launch and Support",
            "step4_description": "Once your site is live, we provide ongoing support and maintenance to address any issues and make updates as needed.",
            "step4_image": "/assets/img/services/wordpress.png"
        }
    }
    ];

    export const WorkProcessSteps =[
        {
            title : "Discovery & Planning",
            iconClass : "",
            iconImage: "/assets/img/services/loupe.png",
            link: "#",

            description : "Lorem Ipusm"
        },
        {
            title : "Design & Prototyping",
            iconClass : "",
            iconImage: "/assets/img/services/web-design.png",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Development & Testing",
            iconClass : "",
            iconImage: "/assets/img/services/coding.png",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Launch & Support",
            iconClass : "",
            iconImage: "/assets/img/services/rocket.png",
            link: "#",
            description : "Lorem Ipusm"
        },
       
    ];

 
