import React from 'react';

export default function NotFound() {
  return (
    <div className='notFound'>

       <img src="/assets/img/error.png" alt="" />
    </div>
  );
}
