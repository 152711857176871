import React from 'react';
import { WorkProcessSteps } from '../Global/Constant';

export default function WorkingProcess({title,subheadingTitle,height,width}) {
  return (
     
    <section className="work-process-section  section-gap overflow-hidden">
    <div className="container">
      <div className="section-heading text-center mb-30">
        <h2 className="title">{title}</h2>
        <span className="tagline">{subheadingTitle}</span>
      </div>
      <div className="fancy-step-boxes">

        {WorkProcessSteps.map((item,key) => {
          return <div key={key} className="single-step-box wow fadeInUp" data-wow-delay="0.3s">
          <div className="step-box-inner" style={{height:height,width:width}}>
            <div className="content">
              <div className="icon">
                {item.iconClass?  <i className={item.iconClass} /> : <img src={item.iconImage} className='iconImage'/>}
               
              </div>
              <h5 className="title">{item.title}</h5>
            </div>
            <span className="step-count">0{key+1}</span>
          </div>
        </div>;
        })} 


      </div>
    </div>
  </section>
  );
}
